import logo from './logo.svg';
import './App.css';
import React, { useState } from "react";
import Multiselect from 'multiselect-react-dropdown';

function App() {
  return (
    <div className="App">
      <div className='flexBox'>
        <div className='left'>
          <Header/><SubscribeForm/>
          <Footer></Footer>
        </div>
        <div className='example'>
          <ExampleEmail/>
        </div>
      </div>
    </div>
  );
}



function Header(){
  return <>
    <h1><img src="panda.png" width="30px"></img>LeetQuest</h1>
    <p>Subscribe to daily leetcode questions by category!</p>
    </>
}

function Footer(){
  return <footer>Website made by <a href="https://tommydong.com">TommyDong</a>. This site is not endorsed by or affiliated to LeetCode.
  <br/>
  <a href="https://www.patreon.com/bePatron?u=6787016" data-patreon-widget-type="become-patron-button">Become a Patron!</a>
  <script async src="https://c6.patreon.com/becomePatronButton.bundle.js"></script></footer>
}


function SubscribeForm(){
  const [email, setEmail] = useState("");
  const multiselectRef = React.createRef();

  function sendEmail(){
    fetch("https://atqozd2h2h.execute-api.us-east-1.amazonaws.com/default/LeetQuest?email="+email+"&categories="+JSON.stringify(multiselectRef.current.getSelectedItems().map((item)=>{
      return item.name;
    }))).then((res)=>{
      res.text().then(alert);
    });
  }

  const categories=["Two Pointers","Binary Search","Hash table","Tree","Stack","Deque","Priority Queue","DFS","BFS","Trie","Linked list","Dynamic Programming","Bit manipulation","Divide and Conquer","String","Union find","Recursion","Graph","Math","Greedy"];
  let i=1;
  const options=categories.map((category)=> ({name:category, id:i++}))
  return (<div className='emailForm'>
    <h2>Subscribe to the LeetQuest!</h2>
    <Multiselect
  ref={multiselectRef}
  selectedValues={[options[0]]}
  options={options} // Options to display in the dropdown
  displayValue="name" // Property name to display in the dropdown options
  style={{multiselectContainer:{width:"350px","margin":"0 auto 10px auto"},searchBox:{"minHeight":"50px"},chips:{"lineHeight": "25px"}}}
  />
    <input name="email" placeholder='youremail@email.domain' type="email" onChange={e => setEmail(e.target.value)} value={email}></input>
    <input type="submit" onClick={sendEmail} value="Subscribe"></input>
    <small style={{color:"grey"}}>Everyday day @ 8am pst, you will recieve one leetcode question that is one of the selected categories.</small>
    </div>);
}

function ExampleEmail(){
  return <div className='exampleEmail'>
    <div className="emailHeader">
      <b>Subject:</b> LeetQuest<br/><br/>
      <b>Email:</b> youremail@email.domain<br/>
    </div>
    <p>Daily Leetcode question: <a href="https://leetcode.com/problems/fibonacci-number/">Fibonacci number</a><br/>
    Category: Recursion/DFS/BFS.<br/>
    Tips: Recursion tips <a href=""> here</a></p>
    <small>Don't like this question? Choose another in the same category <a href="">here</a></small>
  </div>
}
export default App;